import React from "react"
import { Row, Col, H1 } from '@bootstrap-styled/v4'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import RKImage from '../components/Images/RKportrait'

const Content = ({children, portfolio=false, h1, hideOnMobile=false, h1class}) => {
	return (
		<Row className="h-100" noGutters>
			<Col className={classNames("align-self-md-center order-1 order-lg-0 pb-5 pb-md-2", {'pl-lg-4': !portfolio} )}>
				<motion.div
					initial={{ opacity: 0, y:'2rem' }}
					animate={{ opacity: 1, y:0 }}
					exit={{ opacity: 0, y:'2rem' }}
					transition={{ duration: 0.5 }}
					className={classNames("content-box", {'center portfolio': portfolio})}
				>
					<H1 className={classNames("resFont-1 mb-1 mb-lg-2 px-2 px-lg-0", h1class)}>{h1}</H1>
					{children}
				</motion.div>
			</Col>
			{!portfolio &&
				<Col lg="7" xl="6" className={classNames('bg-dark', 'mh-100', 'overflow-hidden', 'px-3', 'm-lg-0', 'p-lg-0', {'d-none d-lg-block':hideOnMobile})}>
					<motion.div
						initial={{ opacity: 0, x:'2rem' }}
						animate={{ opacity: 1, x:0 }}
						exit={{ opacity: 0, x:'2rem' }}
						transition={{ duration: 0.5 }}
						className="d-flex h-100 justify-content-center align-items-center"
					>
						<RKImage />
					</motion.div>
				</Col>
			}
		</Row>
	)
};

export default Content