import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const ImageRK = () => {
  const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "rk-portrait.png" }) {
				childImageSharp {
					fluid(sizes: "(min-width: 2100px) 987px, (min-width: 1920px) 925px, (min-width: 1600px) 765px, (min-width: 1024px) 648px, calc (100vw - 6rem)", srcSetBreakpoints:[648, 765, 925, 987], quality:90){
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
  `)

  return (
    <picture>
      <source
        type="image/webp"
        srcSet={data.placeholderImage.childImageSharp.fluid.srcSetWebp}
        sizes={data.placeholderImage.childImageSharp.fluid.sizes}
      />
      <source
        srcSet={data.placeholderImage.childImageSharp.fluid.srcSet}
        sizes={data.placeholderImage.childImageSharp.fluid.sizes}
      />
      <img
        sizes={data.placeholderImage.childImageSharp.fluid.sizes}
        srcSet={data.placeholderImage.childImageSharp.fluid.srcSet}
        src={data.placeholderImage.childImageSharp.fluid.src}
				alt="Rafał Korzeń"
				className="mw-100"
      />
    </picture>
  )
}

export default ImageRK